import { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster"
import { HelmetProvider } from 'react-helmet-async';
import HomeIntroPage from '@/pages/home/HomeIntroPage';
import NotFoundPage from '@/pages/other/NotFoundPage';
import LoginOrSignupPage from '@/pages/home/LoginOrSignUpPage';
import TermsOfService from '@/pages/other/TermsOfService'
import PrivacyPolicy from '@/pages/other/PrivacyPolicy'
import CreateInbox from '@/pages/inbox/CreateInbox';
import InboxLayout from '@/pages/inbox/InboxLayout'; // 导入新的 InboxLayout
import ShareInbox from '@/pages/inbox/ShareInbox';
import InboxInfo from '@/pages/inbox/InboxInfo';
import InboxFileUploaded from '@/pages/inbox/InboxFileUploaded';
import InboxFilesManagePage from '@/pages/inbox/file-manage/InboxFilesManagePage';
import SettingLayoutPage from '@/pages/setting/SettingLayoutPage';
import ProfilePage from '@/pages/setting/profile/ProfilePage';
import AccountPage from '@/pages/setting/account/AccountPage';
import Dashboard from '@/pages/inbox/Dashboard';
import InboxList from '@/pages/inbox/InboxList';
import ForgotPasswordPage from '@/pages/home/ForgotPasswordPage';
import ResetPasswordPage from '@/pages/home/ResetPasswordPage';

const helmetContext = {};

class App extends Component {
  render() {
    return (
      <HelmetProvider context={helmetContext}>
        <Router>
          <Routes>
            {/* 后面可以考虑使用 children 属性来匹配子页面 */}
            <Route path="/" element={<HomeIntroPage />} />
            <Route path='/signin' element={<LoginOrSignupPage />} />

            <Route path='/inbox/create' element={<CreateInbox />} />
            <Route path='/inbox/share' element={<ShareInbox />} />
            <Route path='/inbox/uploadComplete' element={<InboxFileUploaded />} />
            <Route path='/inbox/:inboxId/info' element={<InboxInfo />} />
            <Route path='/inboxes' element={<InboxLayout />}>
              <Route index element={<Dashboard />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='list' element={<InboxList />} />
            </Route>
            <Route path='/inbox/:inboxId/manage/files' element={<InboxFilesManagePage />} />

            <Route path='/setting' element={<SettingLayoutPage />}>
              <Route index element={<ProfilePage />} />
              <Route path='profile' element={<ProfilePage />} />
              <Route path='account' element={<AccountPage />} />
            </Route>

            <Route path='/terms-of-service' element={<TermsOfService />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />

            <Route path={'/404'} element={<NotFoundPage />} />
            <Route path={'/forgot-password'} element={<ForgotPasswordPage />} />
            <Route path={'/reset-password'} element={<ResetPasswordPage />} />
            <Route path={'/*'} element={<NotFoundPage />} />
          </Routes>
        </Router>

        {/* 添加toaster可以全局进行toast提示 */}
        <Toaster />
      </HelmetProvider>
    );
  }
}

export default App;