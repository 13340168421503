/**
 * 创建收件夹相关
 */
class CreateInboxModel {
    name: string = ''; // 收件箱名称,示例值(my inbox)
    description?: string; // 收件箱描述,示例值(this is my inbox)
    hasPwd?: boolean; // 是否有密码
    pwd?: string; // 密码
    pwdHash?: string; // 密码 hash
    notifyRule?: string; // 通知规则,示例值(none)
    notifyOnDeadline?: boolean; // 是否在到期时邮件通知
    notifyEmail?: string | null; // 通知邮箱，不为空则进行通知
    deadlineTime?: number; // 到期时间，为空则永不过期
    maxFileCount: number = 50; // 最大文件数量
    maxFileSize: number = Number.MAX_SAFE_INTEGER; // 最大文件大小
    limitFileTypeFlag?: boolean; // 是否限制文件类型
    allowFileTypes?: string; // 允许的文件类型
}


/**
 * 分页查询收件夹信息相关
 */
class PageQueryInboxReqModel {
    pageNo: number = 1; // 页码
    pageSize: number = 20; // 每页条数
    state: 'draft' | 'active' | 'paused' | 'expired' | 'closed' | null = null; // 收件夹状态
    name: string | null = null; // 收件夹标题-��糊搜索
    hasPwd: boolean | null = null; // 是否设置密码
    createTimeStart: number | null = null; // 创建时间范围搜索-开始时间
    createTimeEnd: number | null = null; // 创建时间范围搜索-结束时间
    sortField: 'ctime' | 'deadlineTime' | null = 'ctime'; // 排序字段: ctime：创建时间,deadlineTime：到期时间
    sortType: 'asc' | 'desc' | null = 'desc'; // 排序方式： asc:升序，desc:降序
}

/**
 * 排序条件
 */
class SortField {
    fieldName: string = 'ctime'; //排序字段
    sortType: string = 'desc'; //排序方式

    constructor(fieldName: string = 'ctime', sortType: string = 'desc') {
        this.fieldName = fieldName;
        this.sortType = sortType;
    }
}

/**
 * 分页查询收件夹文件信息
 */
class PageQueryInboxFileReqModel {
    pageNo: number = 1; //页码
    pageSize: number = 20; //每页条数
    inboxId: string = ''; //收件箱ID
    fileName: string | null = null; //文件名-模糊搜索
    sortFields: SortField[] = []; //排序条件
}

/**
 * 生成下载token 请求参数
 */
class InboxIdFileIdReqModel {
    inboxId: string = ''; //收件箱ID
    fileIds: string[] = []; //文件ID

    setParams(inboxId: string, fileIds: string[]) {
        this.inboxId = inboxId;
        this.fileIds = fileIds;
    }
}

/**
 * 编辑收件夹基本信息
 */
class EditInboxBasicInfoReqModel {
    inboxId: string = ''; // 收件夹id
    name: string = ''; // 收件夹名称，示例值(my inbox)
    description?: string; // 收件夹描述，示例值(this is my inbox)
    deadlineTime?: number; // 到期时间, 时间戳
}

/**
 * 设置收件夹密码
 */
class SetInboxPasswordReqModel {
    inboxId: string = ''; // 收件夹id
    hasPwd: boolean = false; // 是否开启密码
    pwd: string = ''; // 密码明文
    pwdHash: string = ''; // 密码 hash
}

/**
 * 设置收件夹通知规则
 */
class SetInboxNotifyRuleReqModel {
    inboxId: string = ''; // 收件夹id
    notifyRule: 'none' | 'all' = 'none'; // 通知规则,示例值(none)
    notifyOnDeadline: boolean = false; // 是否在到期时邮件通知
    notifyEmail: string | null = null; // 通知邮箱， 不为空则进行通知
}

class ChangeInboxStateReqModel {
    inboxId: string = ''; // 收件夹id
    newState: 'active' | 'paused' = 'active'; // 新状态
}


// 导出新添加的 EditInboxModel
export { CreateInboxModel, PageQueryInboxReqModel, PageQueryInboxFileReqModel, SortField, InboxIdFileIdReqModel,
    EditInboxBasicInfoReqModel, SetInboxPasswordReqModel, SetInboxNotifyRuleReqModel, ChangeInboxStateReqModel }

