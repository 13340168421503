let host: string = window.location.protocol + "//" + window.location.host;
if (host.includes('localhost') || host.includes('127.0.0.1') || host.includes('yanweijia.cn:3000')) {
    host = 'https://yanweijia.cn'
}

const BoxApi: { [key: string]: string } = {
    //登录注册相关
    register: `${host}/api/user/register/regByEmail`,
    login: `${host}/api/user/login/by-email`,
    logout: `${host}/api/user/logout`,
    userInfoMe: `${host}/api/user/user-info/me`,
    changePassword: `${host}/api/user/change-password`,
    getPublicKey: `${host}/api/common/rsa-pub-key`,
    googleLogin: `${host}/api/user/login/by-google`,
    googleApiKey: '849333342173-j3a76iba7adfr411u26gtp4jr2bqgo2g.apps.googleusercontent.com',
    forgotPassword: `${host}/api/user/forgot-pwd/reset-pwd`,
    forgotPwdExistToken: `${host}/api/user/forgot-pwd/exist-token`,
    resetPassword: `${host}/api/user/forgot-pwd/set-pwd-by-token`,

    
    //Dashboard相关
    statisticsStorage: `${host}/api/dashboard/statistics-storage`,
    statisticsInbox: `${host}/api/dashboard/statistics-inbox`,
    recentActivity: `${host}/api/dashboard/recent-activity`,

    //用户信息相关
    updateProfileInfo: `${host}/api/user/user-info/update-profile`,

    //游客操作收件夹相关
    getInboxInfo: `${host}/api/inbox/guest/info`,
    uploadFile: `${host}/api/inbox/guest/file/upload`,

    //收件夹管理相关
    createInbox: `${host}/api/inbox/manage/create`,
    pageQueryInbox: `${host}/api/inbox/manage/list-by-page`,
    // 设置到期时间, 设置 name.描述等
    editInboxBasicInfo: `${host}/api/inbox/manage/edit/basic-info`,
    deleteInbox: `${host}/api/inbox/manage/delete`,
    setInboxPassword: `${host}/api/inbox/manage/edit/set-pwd`,
    setNotifyRule: `${host}/api/inbox/manage/edit/set-notify-rule`,
    changeInboxState: `${host}/api/inbox/manage/change-state`,

    //收件夹文件管理
    pageQueryInboxFile: `${host}/api/inbox/file/search`,

    //文件相关
    generateDownloadToken: `${host}/api/inbox/file/generate-download-token`,
    downloadSpecificFile: `${host}/api/inbox/file/download`,
    batchDownloadZipFile: `${host}/api/inbox/file/batch-download-zip`,
    batchDeleteFile: `${host}/api/inbox/file/batch-delete`,
    downloadAll: `${host}/api/inbox/file/download-all`,

    //订阅相关
    currentSubscriptionPlan: `${host}/api/subscription/current-plan`,
    allPlans: `${host}/api/subscription/all-plans`,
    tryPlan: `${host}/api/subscription/try-plan`,
}

export default BoxApi;