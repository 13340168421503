import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'


export default function PrivacyPolicy() {
  return (
    <div className="flex flex-col min-h-screen justify-between">
      <BoxPageHeader />
      <main className="flex-grow container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        <p className="text-sm text-gray-600 mb-6">Last updated: Nov 01, 2024</p>
        
        <section className="mb-8">
          <p className="mb-4">
            This Privacy Policy describes how File Box ("us," "we," or "our") collects, uses, and shares information in
            connection with the File Box application (the "Service"). By using the Service, you agree to the practices
            described in this Privacy Policy.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
          <ul className="list-disc list-inside space-y-4">
            <li>
              <strong>Account Information:</strong> When you create an account on File Box, we collect information such as
              your name, email address, and other contact details.
            </li>
            <li>
              <strong>Uploaded Content:</strong> File Box allows you to upload, store, and manage digital files. The
              content you upload to your file inbox is stored securely, and we do not access or use this content for any
              purpose other than to provide the Service.
            </li>
            <li>
              <strong>Usage Information:</strong> We may collect information about how you interact with the Service,
              including log files, device and browser information, and usage patterns.
            </li>
            <li>
              <strong>Cookies:</strong> We may use cookies and similar tracking technologies to improve your experience on
              the Service.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
          <ul className="list-disc list-inside space-y-2">
            <li>To provide, maintain, and improve the Service.</li>
            <li>To respond to your requests and provide customer support.</li>
            <li>To send you important updates, notifications, and other information related to the Service.</li>
            <li>To analyze usage patterns and improve our Service.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Information Sharing</h2>
          <p className="mb-4">
            We do not share your personal information with third parties, except in the following circumstances:
          </p>
          <ul className="list-disc list-inside space-y-2">
            <li>With your consent.</li>
            <li>When required by law or to protect our rights, privacy, safety, or property.</li>
            <li>In connection with the sale, merger, or acquisition of all or part of our company.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Your Choices</h2>
          <p className="mb-4">
            You can control your personal information through your account settings. You can also opt out of certain
            communications, such as promotional emails.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Data Security</h2>
          <p className="mb-4">
            We take data security seriously and have implemented measures to protect your personal information. However, no data
            transmission over the internet can be guaranteed as 100% secure.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Children's Privacy</h2>
          <p className="mb-4">
            File Box is not intended for use by individuals under the age of 13. We do not knowingly collect information from
            children under 13. If you are a parent or guardian and believe we have collected information about a child, please
            contact us, and we will take steps to remove that information.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Changes to this Privacy Policy</h2>
          <p className="mb-4">
            We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new
            Privacy Policy on this page.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
          <p className="mb-4">
            If you have questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@filebox.com" className="text-blue-600 hover:underline">support@filebox.com</a>.
          </p>
        </section>

        <p className="mt-8 font-semibold">
          By using the File Box app, you acknowledge that you have read, understood, and agreed to this Privacy Policy.
        </p>
      </main>
      <BoxPageFooter />
    </div>
  )
}
