import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter';
import { Button } from "@/components/ui/button";
import { CardTitle, CardDescription, CardHeader, CardContent, Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import BoxRequest from '@/common/util/BoxRequest';
import BoxApi from '@/common/BoxApi';

export default function ForgotPasswordPage() {
    const navigate = useNavigate();
    const { toast } = useToast();
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {
        if (!email) {
            toast({
                variant: "destructive",
                title: "Error",
                description: "Please enter your email address",
            });
            return;
        }

        setIsSubmitting(true);
        try {
            const response = await BoxRequest.post(BoxApi.forgotPassword, { email });
            if (response.success) {
                toast({
                    title: "Success",
                    description: "Password reset instructions have been sent to your email",
                });
                navigate('/signin');
            } else {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: response.displayMsg || "Failed to process request",
                });
            }
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error",
                description: "An error occurred. Please try again later.",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className='bg-cover bg-center bg-gradient-to-r from-blue-100 to-purple-100'>
                <BoxPageHeader defaultBgClassName='bg-transparent' />
                <main className="flex items-center justify-center min-h-screen p-4">
                    <Card className="w-[400px]">
                        <CardHeader>
                            <CardTitle>Reset Password</CardTitle>
                            <CardDescription>
                                Enter your email address and we'll send you instructions to reset your password.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <Label htmlFor="email">Email</Label>
                                    <Input
                                        id="email"
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <Button
                                    className="w-full"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? "Sending..." : "Reset Password"}
                                </Button>
                                <div className="text-center">
                                    <Button
                                        variant="link"
                                        onClick={() => navigate('/login')}
                                    >
                                        Back to Login
                                    </Button>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </main>
                <BoxPageFooter />
            </div>
        </>
    );
} 