import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter';
import { Button } from "@/components/ui/button";
import { CardTitle, CardDescription, CardHeader, CardContent, Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import BoxRequest from '@/common/util/BoxRequest';
import BoxApi from '@/common/BoxApi';
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import { FiAlertTriangle } from 'react-icons/fi';
import { BoxRespModel } from '@/common/model/BoxRespModel';

export default function ResetPasswordPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { toast } = useToast();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isValidToken, setIsValidToken] = useState(true);
    const [token, setToken] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    useEffect(() => {
        // 从 URL 中获取参数
        const params = new URLSearchParams(location.search);
        const userIdParam = params.get('userId');
        const tokenParam = params.get('token');

        if (!userIdParam || !tokenParam) {
            setIsValidToken(false);
            return;
        }
        // 在这边调用接口校验 token 是否过期.
        BoxRequest.post(BoxApi.forgotPwdExistToken, {
            token: tokenParam
        }).then((resp: BoxRespModel<null> | null) => {
            if (resp && resp.success) {
                setIsValidToken(true);
            } else {
                setIsValidToken(false);
            }
        }).catch(() => {
            setIsValidToken(false);
        });

        setToken(tokenParam);
    }, [location]);

    const validatePassword = (pwd: string) => {
        if (pwd.length < 8) {
            setPasswordError('Password must be at least 8 characters');
            return false;
        }
        if (pwd.length > 20) {
            setPasswordError('Password must be less than 20 characters');
            return false;
        }
        setPasswordError('');
        return true;
    };

    const validateConfirmPassword = (pwd: string, confirmPwd: string) => {
        if (pwd !== confirmPwd) {
            setConfirmPasswordError('Passwords do not match');
            return false;
        }
        setConfirmPasswordError('');
        return true;
    };

    const handleSubmit = async () => {
        if (!validatePassword(password) || !validateConfirmPassword(password, confirmPassword)) {
            return;
        }

        setIsSubmitting(true);
        try {
            // 获取 RSA 公钥
            const pubKey = await BoxRequest.getServerPublicKey();
            if (!pubKey) {
                throw new Error('Failed to get server public key');
            }

            const rsaEncryptor = new JSEncrypt();
            rsaEncryptor.setPublicKey(pubKey);

            // 加密密码
            const pwdHash = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
            const encryptedPwdHash = rsaEncryptor.encrypt(pwdHash);

            if (!encryptedPwdHash) {
                throw new Error('Encryption failed');
            }

            const response: (BoxRespModel<null> | null) = await BoxRequest.post(BoxApi.resetPassword, {
                token,
                pwdHash: encryptedPwdHash
            });

            if (response && response.success) {
                toast({
                    title: "Success",
                    description: "Your password has been reset successfully",
                });
                navigate('/signin');
            } else {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: response?.displayMsg || "Failed to reset password",
                });
            }
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error",
                description: "An error occurred. Please try again later.",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isValidToken) {
        return (
            <>
                <div className='bg-cover bg-center bg-gradient-to-r from-blue-100 to-purple-100'>
                    <BoxPageHeader defaultBgClassName='bg-transparent' scrollBgClassName='bg-transparent' />
                    <main className="flex items-center justify-center min-h-screen p-4">
                        <Card className="w-[400px]">
                            <CardHeader>
                                <div className="flex flex-col items-center space-y-4">
                                    <FiAlertTriangle className="w-12 h-12 text-yellow-500" />
                                    <CardTitle>Invalid Reset Link</CardTitle>
                                    <CardDescription className="text-center">
                                        The password reset link is invalid or has expired. Please request a new password reset link.
                                    </CardDescription>
                                </div>
                            </CardHeader>
                            <CardContent>
                                <div className="space-y-4">
                                    <Button
                                        className="w-full"
                                        onClick={() => navigate('/forgot-password')}
                                    >
                                        Request New Reset Link
                                    </Button>
                                    <div className="text-center">
                                        <Button
                                            variant="link"
                                            onClick={() => navigate('/signin')}
                                        >
                                            Back to Login
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </main>
                    <BoxPageFooter />
                </div>
            </>
        );
    }

    return (
        <>
            <div className='bg-cover bg-center bg-gradient-to-r from-blue-100 to-purple-100'>
                <BoxPageHeader defaultBgClassName='bg-transparent' />
                <main className="flex items-center justify-center min-h-screen p-4">
                    <Card className="w-[400px]">
                        <CardHeader>
                            <CardTitle>Reset Your Password</CardTitle>
                            <CardDescription>
                                Please enter your new password
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <Label htmlFor="password">New Password</Label>
                                    <Input
                                        id="password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            validatePassword(e.target.value);
                                        }}
                                    />
                                    {passwordError && (
                                        <p className="text-sm text-red-500">{passwordError}</p>
                                    )}
                                </div>
                                <div className="space-y-2">
                                    <Label htmlFor="confirmPassword">Confirm Password</Label>
                                    <Input
                                        id="confirmPassword"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value);
                                            validateConfirmPassword(password, e.target.value);
                                        }}
                                    />
                                    {confirmPasswordError && (
                                        <p className="text-sm text-red-500">{confirmPasswordError}</p>
                                    )}
                                </div>
                                <Button
                                    className="w-full"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting || !!passwordError || !!confirmPasswordError}
                                >
                                    {isSubmitting ? "Resetting..." : "Reset Password"}
                                </Button>
                                <div className="text-center">
                                    <Button
                                        variant="link"
                                        onClick={() => navigate('/signin')}
                                    >
                                        Back to Login
                                    </Button>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </main>
                <BoxPageFooter />
            </div>
        </>
    );
} 