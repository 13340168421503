import { toast } from "@/components/ui/use-toast"
import BoxApi from "@/common/BoxApi"
import BoxRequest from "@/common/util/BoxRequest"
import { useNavigate, useParams } from "react-router-dom"
import { InboxInfoModel } from "@/common/model/InboxInfoModel"
import { useCallback, useEffect, useState, useMemo } from "react"
import { BoxRespModel, PageRespModel } from "@/common/model/BoxRespModel"
import { InboxIdFileIdReqModel, PageQueryInboxFileReqModel, SortField } from "@/common/model/request/InboxReqModel"
import { InboxFileModel } from "@/common/model/response/InboxRespModel"
import { BoxPageHeader } from "@/components/BoxHeaderFooter"
import { EditInboxBasicInfoReqModel, SetInboxNotifyRuleReqModel } from '@/common/model/request/InboxReqModel'
import { debounce } from 'lodash'
import { handleFileDownload } from "@/common/util/FileDownloadUtil";

// Import new components
import { InboxHeader } from "./components/InboxHeader"
import { FileSearchBar } from "./components/FileSearchBar"
import { FileTable } from "./components/FileTable"

export default function InboxFilesManagePage() {
  const navigate = useNavigate();
  const { inboxId } = useParams();
  const defaultInboxInfo: InboxInfoModel = new InboxInfoModel();
  const [inboxInfo, setInboxInfo] = useState<InboxInfoModel>(defaultInboxInfo);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [fileList, setFileList] = useState<InboxFileModel[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastSearchTerm, setLastSearchTerm] = useState<string>('');

  const [selectedFiles, setSelectedFiles] = useState<string[]>([])

  // Keep all the existing functions
  const fetchInboxInfo = useCallback(async () => {
    if (!inboxId)
      return;
    try {
      const resp: BoxRespModel<InboxInfoModel> = await BoxRequest.post(BoxApi.getInboxInfo, { inboxId: inboxId });
      if (resp.success && null != resp.data) {
        setInboxInfo(resp.data);
      } else {
        throw new Error(resp.displayMsg || 'Get inbox info fail.');
      }
    } catch (err) {
      toast({
        title: "Get inbox info fail.",
        description: `${err}`,
      });
      setTimeout(() => {
        navigate('/404');
      }, 2000);
    }
  }, [inboxId, navigate])

  const loadFileList = useCallback(async (pageNo: number, pageSize: number, search: string = '') => {
    if (!inboxId) {
      console.info('cannot load inbox files, inboxId is null.');
      return;
    }

    setIsLoading(true);
    let reqModel = new PageQueryInboxFileReqModel();
    reqModel.pageNo = pageNo;
    reqModel.pageSize = pageSize;
    reqModel.inboxId = String(inboxId);
    reqModel.sortFields = [new SortField('ctime', 'desc')];
    if (search) {
      reqModel.fileName = search;
    }

    setIsSearching(true);
    try {
      const resp: BoxRespModel<PageRespModel<InboxFileModel>> = await BoxRequest.post(BoxApi.pageQueryInboxFile, reqModel);
      if (!resp.success || null == resp.data) {
        throw new Error(resp.displayMsg || 'Get inbox files fail.');
      }
      let pageResp: PageRespModel<InboxFileModel> = resp.data;
      setCurrentPage(pageResp.current);
      setTotalPages(pageResp.pages);
      setTotalCount(pageResp.total);
      if (0 === pageResp.size) {
        console.info(`inbox file list is empty, currentPage:${pageResp.current}, totalPages:${pageResp.pages}`);
        setFileList([]);
        return;
      }
      setFileList(pageResp.records);

    } catch (err) {
      toast({
        title: "Get inbox files fail.",
        description: `${err}`,
      });
    } finally {
      setIsLoading(false);
      setIsSearching(false);
    }
  }, [inboxId])

  const batchDeleteFiles = (inboxId: string, fileIds: string[]) => {
    let reqModel: InboxIdFileIdReqModel = new InboxIdFileIdReqModel();
    reqModel.setParams(inboxId, fileIds);

    BoxRequest.post(BoxApi.batchDeleteFile, reqModel)
      .then((resp: BoxRespModel<string>) => {
        if (resp.success) {
          toast({
            title: "Delete files success.",
            description: "Delete files success.",
          });
          loadFileList(currentPage, pageSize);
        } else {
          throw new Error(resp.displayMsg);
        }
      }).catch((err) => {
        toast({
          title: "Delete files fail.",
          description: `${err}`,
        });
      });
  }

  const downloadSpecificFile = (fileId: string, operateType: string) => {
    if (!operateType) {
      operateType = "view";
    }

    let reqModel: InboxIdFileIdReqModel = new InboxIdFileIdReqModel();
    reqModel.inboxId = String(inboxId);
    reqModel.fileIds = [fileId];

    BoxRequest.post(BoxApi.generateDownloadToken, reqModel)
      .then((resp: BoxRespModel<string>) => {
        if (resp.success && resp.data) {
          const downloadUrl = `${BoxApi.downloadSpecificFile}?operateType=${operateType}&inboxId=${inboxId}&fileId=${fileId}&token=${resp.data}`;
          handleFileDownload(downloadUrl);
        } else {
          throw new Error(resp.displayMsg || 'Generate download token fail.');
        }
      }).catch((err) => {
        toast({
          title: "Failed to download file.",
          description: `${err}`,
        });
      });
  };

  const shareInboxByLink = () => {
    console.info(`shareInboxByLink clicked,inboxId:${inboxId}`)
    navigator.clipboard.writeText(`${window.location.origin}/inbox/${inboxId}/info`)
    toast({
      title: "Link Copied",
      description: "now you can share the link to others.",
    });
  }

  const confirmSetDate = async (newDate: Date) => {
    if (!inboxId || !newDate) return;

    let reqModel = new EditInboxBasicInfoReqModel();
    reqModel.inboxId = inboxId;
    reqModel.deadlineTime = newDate.getTime();
    reqModel.name = inboxInfo.name || '';
    reqModel.description = inboxInfo.description || '';

    try {
      const resp: BoxRespModel<boolean> = await BoxRequest.post(BoxApi.editInboxBasicInfo, reqModel);
      if (resp.success) {
        toast({
          title: "Deadline time updated",
          description: "The deadline time has been successfully updated.",
        });
        fetchInboxInfo();
      } else {
        throw new Error(resp.displayMsg || 'Failed to update deadline time');
      }
    } catch (err) {
      toast({
        variant: 'destructive',
        title: "Update Failed",
        description: `${err}`,
      });
    }
  };

  const handleSetNotify = async (enabled: boolean, email: string, notifyOnDeadline: boolean) => {
    if (!inboxId) return;

    let reqModel = new SetInboxNotifyRuleReqModel();
    reqModel.inboxId = inboxId;
    reqModel.notifyRule = enabled ? 'all' : 'none';
    reqModel.notifyOnDeadline = notifyOnDeadline;
    reqModel.notifyEmail = enabled ? email : null;

    try {
      const resp: BoxRespModel<boolean> = await BoxRequest.post(BoxApi.setNotifyRule, reqModel);
      if (resp.success) {
        toast({
          title: "Notification settings updated",
          description: "The notification settings have been successfully updated.",
        });
        fetchInboxInfo();
      } else {
        throw new Error(resp.displayMsg || 'Failed to update notification settings');
      }
    } catch (err) {
      toast({
        variant: 'destructive',
        title: "Update Failed",
        description: `${err}`,
      });
    }
  };

  useEffect(() => {
    fetchInboxInfo();
  }, [fetchInboxInfo])

  useEffect(() => {
    loadFileList(currentPage, pageSize);
  }, [currentPage, pageSize, loadFileList]);

  const debouncedSearch = useMemo(
    () => debounce((searchValue: string) => {
      setIsSearching(true);
      setLastSearchTerm(searchValue);
      loadFileList(1, pageSize, searchValue).finally(() => {
        setIsSearching(false);
      });
    }, 300),
    [pageSize, loadFileList]
  );

  const handleSearchInput = (value: string) => {
    setSearchTerm(value);
    if (value) {
      setIsSearching(true);
    }
    debouncedSearch(value);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setLastSearchTerm('');
    setIsSearching(false);
    loadFileList(1, pageSize, '');
  };

  const handleSelectFile = (fileId: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedFiles([...selectedFiles, fileId])
    } else {
      setSelectedFiles(selectedFiles.filter(id => id !== fileId))
    }
  }

  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedFiles(fileList.map(file => file.fileId))
    } else {
      setSelectedFiles([])
    }
  }

  const handleBatchDownload = async () => {
    if (selectedFiles.length === 0) {
      console.info('selectedFiles is empty, cannot batch download.')
      return
    }

    try {
      let reqModel: InboxIdFileIdReqModel = new InboxIdFileIdReqModel();
      reqModel.inboxId = String(inboxId);
      reqModel.fileIds = selectedFiles;

      const resp: BoxRespModel<string> = await BoxRequest.post(BoxApi.generateDownloadToken, reqModel)
      if (resp.success && resp.data) {
        const downloadUrl = `${BoxApi.batchDownloadZipFile}?inboxId=${inboxId}&fileIds=${selectedFiles.join(',')}&token=${resp.data}`;
        handleFileDownload(downloadUrl);
      } else {
        throw new Error(resp.displayMsg || 'Generate download token fail.')
      }
    } catch (err) {
      toast({
        title: "Failed to download files",
        description: `${err}`,
      })
    }
  }

  const handleBatchDelete = () => {
    if (selectedFiles.length === 0) return
    
    if (window.confirm(`Are you sure you want to delete ${selectedFiles.length} files?`)) {
      batchDeleteFiles(inboxId!, selectedFiles)
      setSelectedFiles([])
    }
  }

  // 在文件列表加载完成后清空选中状态
  useEffect(() => {
    setSelectedFiles([])
  }, [fileList])

  const handleDownloadAll = async () => {
    try {
      let reqModel: InboxIdFileIdReqModel = new InboxIdFileIdReqModel();
      reqModel.inboxId = String(inboxId);
      
      const resp: BoxRespModel<string> = await BoxRequest.post(BoxApi.generateDownloadToken, reqModel)
      if (resp.success && resp.data) {
        const downloadUrl = `${BoxApi.downloadAll}?inboxId=${inboxId}&token=${resp.data}`;
        handleFileDownload(downloadUrl);
      } else {
        throw new Error(resp.displayMsg || 'Generate download token fail.')
      }
    } catch (err) {
      toast({
        title: "Failed to download all files",
        description: `${err}`,
      })
    }
  }

  return (
    <div className="bg-white flex flex-col min-h-screen">
      <BoxPageHeader />
      <div className="px-6 flex-grow flex flex-col">
        <div className="mt-2 border-b mb-2"></div>
        
        <InboxHeader 
          inboxInfo={inboxInfo}
          inboxId={inboxId!}
          onShareLink={shareInboxByLink}
          onUpdateDeadline={confirmSetDate}
          onUpdateNotify={handleSetNotify}
        />
        
        <FileSearchBar
          searchTerm={searchTerm}
          selectedCount={selectedFiles.length}
          onSearch={handleSearchInput}
          onClear={clearSearch}
          onRefresh={() => loadFileList(currentPage, pageSize)}
          onDownloadAll={handleDownloadAll}
          onBatchDelete={handleBatchDelete}
          onBatchDownload={handleBatchDownload}
        />
        
        <FileTable
          files={fileList}
          selectedFiles={selectedFiles}
          onSelectFile={handleSelectFile}
          onSelectAll={handleSelectAll}
          currentPage={currentPage}
          totalPages={totalPages}
          totalCount={totalCount}
          pageSize={pageSize}
          isLoading={isLoading}
          isSearching={isSearching}
          lastSearchTerm={lastSearchTerm}
          onPageChange={(page, size) => {
            setCurrentPage(page)
            setPageSize(size)
          }}
          onView={(fileId) => downloadSpecificFile(fileId, "view")}
          onDownload={(fileId) => downloadSpecificFile(fileId, "download")}
          onDelete={(fileId) => batchDeleteFiles(inboxId!, [fileId])}
        />
      </div>
    </div>
  )
}
