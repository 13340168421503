export interface FileTypeOption {
  value: string;
  label: string;
  description: string;
  group: string;
}

export const FILE_TYPE_GROUPS = {
  IMAGES: "Images",
  DOCUMENTS: "Documents",
  ARCHIVES: "Archives",
  MEDIA: "Media",
  CODE: "Code",
  OTHER: "Other"
} as const;

export const COMMON_FILE_TYPES: FileTypeOption[] = [
  { 
    value: "jpg,jpeg,png,gif,webp,svg", 
    label: "Images", 
    description: "Common image formats",
    group: FILE_TYPE_GROUPS.IMAGES
  },
  { 
    value: "pdf,doc,docx,txt,rtf", 
    label: "Documents", 
    description: "Text and document formats",
    group: FILE_TYPE_GROUPS.DOCUMENTS
  },
  { 
    value: "xls,xlsx,csv", 
    label: "Spreadsheets", 
    description: "Spreadsheet formats",
    group: FILE_TYPE_GROUPS.DOCUMENTS
  },
  { 
    value: "ppt,pptx", 
    label: "Presentations", 
    description: "Presentation formats",
    group: FILE_TYPE_GROUPS.DOCUMENTS
  },
  { 
    value: "zip,rar,7z,tar,gz", 
    label: "Archives", 
    description: "Compressed file formats",
    group: FILE_TYPE_GROUPS.ARCHIVES
  },
  { 
    value: "mp4,mov,avi,wmv", 
    label: "Videos", 
    description: "Video formats",
    group: FILE_TYPE_GROUPS.MEDIA
  },
  { 
    value: "mp3,wav,ogg,m4a", 
    label: "Audio", 
    description: "Audio formats",
    group: FILE_TYPE_GROUPS.MEDIA
  },
  { 
    value: "js,ts,jsx,tsx,html,css,json", 
    label: "Web Development", 
    description: "Web development files",
    group: FILE_TYPE_GROUPS.CODE
  }
]; 