import {
  // CreditCard,
  FileSymlink,
  LogOut,
  Mail,
  Plus,
  PlusCircle,
  Settings,
  User,
  FileText
} from "lucide-react"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Link } from "react-router-dom"
import { useToast } from "@/components/ui/use-toast"
import BoxRequest from "@/common/util/BoxRequest"


interface ChildrenDto {
  children: React.ReactNode
}
const AvatarDropDown: React.FC<ChildrenDto> = ({ children }) => {

  const { toast } = useToast();


  // 登出逻辑
  const logout = () => {
    if (!BoxRequest.getLoginFlag()) {
      window.location.reload();
      return;
    }

    BoxRequest.logout();
    toast({
      title: "Logout Success",
      description: '',
      duration: 3000
    });
    
    // 使用 window.location.reload() 来刷新页面
    window.location.reload();
  }


  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <Link to='/setting/profile'>
            <DropdownMenuItem className="cursor-pointer">
              <User className="mr-2 h-4 w-4" />
              <span>Profile</span>
              <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
            </DropdownMenuItem>
          </Link>
          {/* <DropdownMenuItem className="cursor-pointer">
            <CreditCard className="mr-2 h-4 w-4" />
            <span>Billing</span>
            <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
          </DropdownMenuItem> */}
          <Link to='/setting/account'>
            <DropdownMenuItem className="cursor-pointer">
              <Settings className="mr-2 h-4 w-4" />
              <span>Settings</span>
              <DropdownMenuShortcut></DropdownMenuShortcut>
            </DropdownMenuItem>
          </Link>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <Link to='/inbox/create'>
            <DropdownMenuItem className="cursor-pointer">
              <Plus className="mr-2 h-4 w-4" />
              <span>New File Inbox</span>
              <DropdownMenuShortcut>⇧⌘T</DropdownMenuShortcut>
            </DropdownMenuItem>
          </Link>
        </DropdownMenuGroup>

        <DropdownMenuSeparator />


        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <PlusCircle className="mr-2 h-4 w-4" />
            <span>more...</span>
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>

              <Link to='/#faqs'>
                <DropdownMenuItem className="cursor-pointer">
                  <FileSymlink className="mr-2 h-4 w-4" />
                  <span>FAQs</span>
                </DropdownMenuItem>
              </Link>
              <Link to='/terms-of-service'>
                <DropdownMenuItem className="cursor-pointer">
                  <FileText className="mr-2 h-4 w-4" />
                  <span>Terms of service</span>
                </DropdownMenuItem>
              </Link>
              <Link to='/privacy-policy'>
                <DropdownMenuItem className="cursor-pointer">
                  <FileText className="mr-2 h-4 w-4" />
                  <span>Privacy policy</span>
                </DropdownMenuItem>
              </Link>
              <Link to='/#contact-us'>
                <DropdownMenuItem className="cursor-pointer">
                  <Mail className="mr-2 h-4 w-4" />
                  <span>Feedback</span>
                </DropdownMenuItem>
              </Link>

            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>


        <DropdownMenuSeparator />

        <DropdownMenuItem className="cursor-pointer" onClick={() => logout()}>
          <LogOut className="mr-2 h-4 w-4" />
          <span>Log out</span>
          <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
export default AvatarDropDown;
