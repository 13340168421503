import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Switch } from "@/components/ui/switch"
import { useState, useEffect, useRef } from 'react'
import { CreateInboxModel } from '@/common/model/request/InboxReqModel'
import { useToast } from '@/components/ui/use-toast'
import BoxApi from '@/common/BoxApi'
import BoxRequest from '@/common/util/BoxRequest'
import { BoxRespModel } from '@/common/model/BoxRespModel'
import { InboxInfoModel } from '@/common/model/InboxInfoModel'
import { useNavigate } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import { SubscriptionPlanModel, CurrentSubscriptionPlanModel } from '@/common/model/response/SubscriptionModel'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { Badge } from "@/components/ui/badge"
import { COMMON_FILE_TYPES, FileTypeOption, FILE_TYPE_GROUPS } from "@/common/constants/FileTypes"
import { X } from "lucide-react"

export default function Component() {
  const { toast } = useToast();
  const navigate = useNavigate();

  const today = new Date();
  const currentTimeStr = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}T${String(today.getHours()).padStart(2, '0')}:${String(today.getMinutes()).padStart(2, '0')}`;
  const sevenDayLater = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
  const sevenDayLaterStr = `${sevenDayLater.getFullYear()}-${String(sevenDayLater.getMonth() + 1).padStart(2, '0')}-${String(sevenDayLater.getDate()).padStart(2, '0')}T${String(sevenDayLater.getHours()).padStart(2, '0')}:${String(sevenDayLater.getMinutes()).padStart(2, '0')}`;

  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [cutoffTime, setCutoffTime] = useState<string>(sevenDayLaterStr)
  const [hasPwd, setHasPwd] = useState<boolean>(false)
  const [pwd, setPwd] = useState<string>('')
  const [notifyOnDeadline, setNotifyOnDeadline] = useState<boolean>(false)
  const [notifyEmail, setNotifyEmail] = useState<string>('')
  const [maxFileCount, setMaxFileCount] = useState<number>(50)
  const [maxFileSize, setMaxFileSize] = useState<number>(10)
  const [limitFileTypeFlag, setLimitFileTypeFlag] = useState<boolean>(false)
  const [allowFileTypes, setAllowFileTypes] = useState<string>('')
  const [userEmail, setUserEmail] = useState<string>('')
  const [currentPlan, setCurrentPlan] = useState<SubscriptionPlanModel | null>(null);
  const [selectedFileTypes, setSelectedFileTypes] = useState<FileTypeOption[]>([]);
  const [isFileTypeCommandOpen, setIsFileTypeCommandOpen] = useState(false);
  const [fileTypeInput, setFileTypeInput] = useState<string>('');

  // 在组件加载时获取用户信息和订阅计划
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // 获取用户信息
        const userInfo = await BoxRequest.getUserInfo();
        if (userInfo && userInfo.email) {
          setUserEmail(userInfo.email);
        }

        // 获取当前订阅计划
        const resp: BoxRespModel<CurrentSubscriptionPlanModel> = await BoxRequest.get(BoxApi.currentSubscriptionPlan, {});
        if (resp.success && resp.data) {
          // 获取订阅计划详细信息
          const planResp: BoxRespModel<SubscriptionPlanModel[]> = await BoxRequest.get(BoxApi.allPlans, {});
          if (planResp.success && planResp.data) {
            const currentPlanDetails = planResp.data.find(plan => plan.planCode === resp.data?.planCode);
            if (currentPlanDetails) {
              setCurrentPlan(currentPlanDetails);
              // 设置默认值不超过订阅计划限制
              setMaxFileCount(currentPlanDetails.maxInboxLimit);
              setMaxFileSize(Math.floor(currentPlanDetails.maxFileSizeLimit / (1024 * 1024)));
            }
          }
        }
      } catch (err) {
        console.error('Failed to fetch initial data:', err);
        toast({
          variant: "destructive",
          title: "Loading Failed",
          description: "Failed to get subscription plan information",
        });
      }
    };

    fetchInitialData();
  }, [toast]);

  // 检参数
  const checkParam = (): boolean => {
    if (!title.trim()) {
      toast({
        variant: "destructive",
        title: "Create Failed",
        description: 'Title cannot be empty',
      })
      return false;
    }

    if (!description.trim()) {
      toast({
        variant: "destructive",
        title: "Create Failed",
        description: 'Description cannot be empty',
      })
      return false;
    }

    const cutoffDate = new Date(cutoffTime);
    if (cutoffDate.getTime() < Date.now()) {
      toast({
        variant: "destructive",
        title: "Create Failed",
        description: 'Deadline time cannot be in the past',
      })
      return false;
    }

    if (hasPwd && !pwd.trim()) {
      toast({
        variant: "destructive",
        title: "Create Failed",
        description: 'Password cannot be empty when enabled',
      })
      return false;
    }

    if (notifyOnDeadline && !notifyEmail.trim()) {
      toast({
        variant: "destructive",
        title: "Create Failed",
        description: 'Notification email cannot be empty when enabled',
      })
      return false;
    }

    return true;
  }

  // 创建收件夹功能
  const createInbox = () => {
    if (!checkParam()) {
      return;
    }

    const createInboxModel: CreateInboxModel = {
      name: title,
      description: description,
      hasPwd: hasPwd,
      pwd: hasPwd ? pwd : undefined,
      pwdHash: hasPwd ? CryptoJS.SHA256(pwd).toString(CryptoJS.enc.Hex) : undefined,
      notifyOnDeadline: notifyOnDeadline,
      notifyEmail: notifyOnDeadline ? notifyEmail : null,
      deadlineTime: new Date(cutoffTime).getTime(),
      maxFileCount: maxFileCount,
      maxFileSize: maxFileSize * 1024 * 1024,
      limitFileTypeFlag: limitFileTypeFlag,
      allowFileTypes: limitFileTypeFlag ? allowFileTypes : undefined,
    }

    BoxRequest.post(BoxApi.createInbox, createInboxModel)
      .then((resp: BoxRespModel<InboxInfoModel>) => {
        if (resp.success) {
          toast({
            title: "Create Success",
            description: 'Inbox created successfully',
          })
          navigate(`/inbox/share?inboxId=${resp.data?.inboxId ?? ''}`, { state: { inboxInfo: resp.data } });
        } else {
          toast({
            variant: "destructive",
            title: "Create Failed",
            description: resp.displayMsg
          })
        }
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Create Failed",
          description: err.message,
        })
      })
  }

  // 修改 setNotifyOnDeadline 函数
  const handleNotifyOnDeadlineChange = (checked: boolean) => {
    setNotifyOnDeadline(checked);
    if (checked && !notifyEmail && userEmail) {
      setNotifyEmail(userEmail);
    }
  }

  // 添加辅助函数
  const updateAllowedFileTypes = () => {
    setAllowFileTypes(selectedFileTypes.map(t => t.value).join(','));
  };

  // 添加一个延时关闭的引用
  const closeTimeoutRef = useRef<number | null>(null);

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <BoxPageHeader />
      <main className="flex-grow container mx-auto px-4 py-8">
        <section className="bg-white shadow-md rounded-lg p-6 max-w-2xl mx-auto" aria-label="Create File Form">
          <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">Create New Inbox</h1>
          <form onSubmit={(e) => { e.preventDefault(); createInbox(); }} className="space-y-6">
            <div>
              <Label htmlFor="inboxTitle" className="text-sm font-medium text-gray-700">Inbox Title</Label>
              <Input 
                id="inboxTitle" 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} 
                placeholder="Enter inbox title"
                className="mt-1"
                required
              />
            </div>
            <div>
              <Label htmlFor="inboxDescription" className="text-sm font-medium text-gray-700">Description</Label>
              <Textarea 
                id="inboxDescription"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Describe the purpose of this inbox"
                className="mt-1"
                required
              />
            </div>
            <div>
              <Label htmlFor="inboxDeadline" className="text-sm font-medium text-gray-700">Deadline</Label>
              <Input 
                id="inboxDeadline"
                type="datetime-local" 
                min={currentTimeStr}
                value={cutoffTime} 
                onChange={(e) => setCutoffTime(e.target.value)} 
                className="mt-1"
                required
              />
            </div>
            <div className="flex items-center space-x-2">
              <Switch
                id="hasPwd"
                checked={hasPwd}
                onCheckedChange={setHasPwd}
              />
              <Label htmlFor="hasPwd" className="cursor-pointer text-sm font-medium text-gray-700">Enable Password Protection</Label>
            </div>
            {hasPwd && (
              <div>
                <Label htmlFor="pwd" className="text-sm font-medium text-gray-700">Password</Label>
                <Input 
                  id="pwd" 
                  type="password"
                  value={pwd} 
                  onChange={(e) => setPwd(e.target.value)} 
                  placeholder="Enter password"
                  className="mt-1"
                />
              </div>
            )}
            <div className="flex items-center space-x-2">
              <Switch
                id="notifyOnDeadline"
                checked={notifyOnDeadline}
                onCheckedChange={handleNotifyOnDeadlineChange}
              />
              <Label htmlFor="notifyOnDeadline" className="cursor-pointer text-sm font-medium text-gray-700">Notify on Deadline</Label>
            </div>
            {notifyOnDeadline && (
              <div>
                <Label htmlFor="notifyEmail" className="text-sm font-medium text-gray-700">Notification Email</Label>
                <Input 
                  id="notifyEmail" 
                  type="email"
                  value={notifyEmail} 
                  onChange={(e) => setNotifyEmail(e.target.value)} 
                  placeholder="Enter email for notifications"
                  className="mt-1"
                />
              </div>
            )}
            <div>
              <Label htmlFor="maxFileCount" className="text-sm font-medium text-gray-700">
                Maximum File Count {currentPlan && `(Limit: ${currentPlan.maxInboxLimit})`}
              </Label>
              <Input 
                id="maxFileCount" 
                type="number"
                value={maxFileCount} 
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if (currentPlan && value > currentPlan.maxInboxLimit) {
                    toast({
                      variant: "destructive",
                      title: "Limit Exceeded",
                      description: `Current plan allows maximum ${currentPlan.maxInboxLimit} files`,
                    });
                    return;
                  }
                  setMaxFileCount(value);
                }} 
                min="1"
                max={currentPlan?.maxInboxLimit || 999999}
                className="mt-1"
              />
            </div>
            <div>
              <Label htmlFor="maxFileSize" className="text-sm font-medium text-gray-700">
                Maximum File Size (MB) {currentPlan && `(Limit: ${Math.floor(currentPlan.maxFileSizeLimit / (1024 * 1024))}MB)`}
              </Label>
              <Input 
                id="maxFileSize" 
                type="number"
                value={maxFileSize} 
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  const maxSizeMB = currentPlan ? Math.floor(currentPlan.maxFileSizeLimit / (1024 * 1024)) : 999999;
                  if (currentPlan && value > maxSizeMB) {
                    toast({
                      variant: "destructive",
                      title: "Limit Exceeded",
                      description: `Current plan allows maximum ${maxSizeMB}MB per file`,
                    });
                    return;
                  }
                  setMaxFileSize(value);
                }} 
                min="1"
                max={currentPlan ? Math.floor(currentPlan.maxFileSizeLimit / (1024 * 1024)) : 999999}
                className="mt-1"
              />
            </div>
            <div className="flex items-center space-x-2">
              <Switch
                id="limitFileTypeFlag"
                checked={limitFileTypeFlag}
                onCheckedChange={setLimitFileTypeFlag}
              />
              <Label htmlFor="limitFileTypeFlag" className="text-sm font-medium text-gray-700">Limit File Types</Label>
            </div>
            {limitFileTypeFlag && (
              <div className="space-y-2">
                <Label className="text-sm font-medium text-gray-700">Allowed File Types</Label>
                <Command className="relative border rounded-lg overflow-visible">
                  <div className="flex flex-wrap gap-2 p-2">
                    {selectedFileTypes.map((type) => (
                      <div key={type.value} className="flex flex-wrap gap-1">
                        {type.value.split(',').map(ext => (
                          <Badge key={ext} variant="secondary" className="flex items-center gap-1">
                            {ext}
                            <X
                              className="h-3 w-3 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation(); // 防止触发 Command 的点击事件
                                const remainingTypesInGroup = selectedFileTypes
                                  .filter(t => t.value === type.value)
                                  .flatMap(t => t.value.split(','))
                                  .filter(t => t !== ext);
                                
                                if (remainingTypesInGroup.length === 0) {
                                  setSelectedFileTypes(selectedFileTypes.filter(t => t.value !== type.value));
                                } else {
                                  const updatedType = {
                                    ...type,
                                    value: remainingTypesInGroup.join(',')
                                  };
                                  setSelectedFileTypes(selectedFileTypes.map(t => 
                                    t.value === type.value ? updatedType : t
                                  ));
                                }
                                updateAllowedFileTypes();
                              }}
                            />
                          </Badge>
                        ))}
                      </div>
                    ))}
                    <CommandInput 
                      placeholder={selectedFileTypes.length === 0 ? "Search or enter file types (e.g. pdf, images)..." : "Add more file types..."}
                      value={fileTypeInput}
                      onValueChange={setFileTypeInput}
                      className="border-0 outline-none focus:ring-0 p-0 h-8"
                      onFocus={() => {
                        // 清除可能存在的延时关闭
                        if (closeTimeoutRef.current) {
                          clearTimeout(closeTimeoutRef.current);
                        }
                        setIsFileTypeCommandOpen(true);
                      }}
                      onBlur={() => {
                        // 使用延时关闭，给点击选项留出时间
                        closeTimeoutRef.current = setTimeout(() => {
                          setIsFileTypeCommandOpen(false);
                        }, 200);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && fileTypeInput.trim()) {
                          e.preventDefault();
                          
                          const presetType = COMMON_FILE_TYPES.find(type => 
                            type.label.toLowerCase() === fileTypeInput.trim().toLowerCase() ||
                            type.value.split(',').some(ext => ext === fileTypeInput.trim().toLowerCase())
                          );

                          if (presetType) {
                            if (!selectedFileTypes.some(t => t.value === presetType.value)) {
                              setSelectedFileTypes([...selectedFileTypes, presetType]);
                              setAllowFileTypes([...selectedFileTypes, presetType]
                                .map(t => t.value)
                                .join(','));
                            }
                          } else {
                            const customType: FileTypeOption = {
                              value: fileTypeInput.trim().toLowerCase(),
                              label: fileTypeInput.trim().toLowerCase(),
                              description: "Custom file type",
                              group: FILE_TYPE_GROUPS.OTHER
                            };
                            setSelectedFileTypes([...selectedFileTypes, customType]);
                            setAllowFileTypes([...selectedFileTypes, customType]
                              .map(t => t.value)
                              .join(','));
                          }
                          setFileTypeInput('');
                        }
                      }}
                    />
                  </div>
                  <CommandList className="absolute w-full z-10 top-full mt-1 bg-white rounded-md shadow-lg" hidden={!isFileTypeCommandOpen}>
                    <CommandEmpty>No file type found. Press Enter to add custom type.</CommandEmpty>
                    {Object.values(FILE_TYPE_GROUPS).map((group) => {
                      const groupTypes = COMMON_FILE_TYPES.filter(type => 
                        type.group === group && 
                        !selectedFileTypes.some(t => t.value === type.value) &&
                        (type.label.toLowerCase().includes(fileTypeInput.toLowerCase()) ||
                         type.value.toLowerCase().includes(fileTypeInput.toLowerCase()))
                      );
                      
                      // 只显示有匹配项的组
                      if (groupTypes.length === 0) return null;
                      
                      return (
                        <CommandGroup key={group} heading={group}>
                          {groupTypes.map((type) => (
                            <CommandItem
                              key={type.value}
                              value={type.label}
                              onSelect={() => {
                                // 清除延时关闭
                                if (closeTimeoutRef.current) {
                                  clearTimeout(closeTimeoutRef.current);
                                }
                                setSelectedFileTypes([...selectedFileTypes, type]);
                                setAllowFileTypes([...selectedFileTypes, type]
                                  .map(t => t.value)
                                  .join(','));
                                setFileTypeInput('');
                                // 选择后主动关闭下拉框
                                setIsFileTypeCommandOpen(false);
                              }}
                            >
                              <div className="flex flex-col">
                                <span className="font-medium">{type.label}</span>
                                <span className="text-xs text-gray-500">
                                  {type.description} ({type.value})
                                </span>
                              </div>
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      );
                    })}
                  </CommandList>
                </Command>
                <p className="text-xs text-gray-500">
                  Type a file extension or select from common types. Press Enter to add custom types.
                </p>
              </div>
            )}
            <button 
              type="submit"
              className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary/80 transition duration-300"
            >
              Create Inbox
            </button>
          </form>
        </section>
      </main>
      <BoxPageFooter />
    </div>
  )
}
