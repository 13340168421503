import React from 'react';

export const LoadingSection: React.FC = () => {
  return (
    <div className="py-12 flex items-center justify-center">
      <div className="animate-pulse space-y-4">
        <div className="h-4 bg-gray-200 rounded w-48"></div>
        <div className="h-4 bg-gray-200 rounded w-64"></div>
      </div>
    </div>
  );
}; 