/**
 * Universal file download handler that works across different devices and browsers
 * @param url The URL to download
 * @param options Additional options for download
 */
export const handleFileDownload = (url: string) => {
  // Try to open in new window first
  const newWindow = window.open(url);
  
  // If popup is blocked or on mobile devices, fallback to current window
  if (newWindow === null) {
    window.location.href = url;
  }
}; 