import { Link, useLocation } from "react-router-dom"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import BoxRequest from "@/common/util/BoxRequest";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AvatarDropDown from "@/pages/setting/AvatarDropDown";


interface BoxPageHeaderProps {
  //默认背景 class name  bg-transparent
  defaultBgClassName?: string;
  //滚动后的背景 class name, 例如:bg-blue-100 bg-opacity-50
  scrollBgClassName?: string;
}

let logoColorStyle = 'select-none bg-gradient-to-r from-[#6366F1] to-[#EC4899] bg-clip-text text-transparent';


const BoxPageHeader: React.FC<BoxPageHeaderProps> = ({ defaultBgClassName = 'bg-white bg-opacity-80', scrollBgClassName = 'bg-white shadow-md' }) => {

  let defaultAvatar = '/image/avatar2.jpg';
  const [loginFlag, setLoginFlag] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [avartar, setAvartar] = useState(defaultAvatar);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const handleLogoClick = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/');
    }
  }

  useEffect(() => {
    const checkLoginStatus = async () => {
      setLoginFlag(BoxRequest.getLoginFlag());
      const userInfo = await BoxRequest.getUserInfo();
      if (userInfo) {
        setAvartar(userInfo?.headUrl || defaultAvatar);
      }
    };

    checkLoginStatus();

    // 页面滚动监听
    const onScroll = () => {
      setScrollPosition(window.scrollY);
    }
    window.addEventListener('scroll', onScroll);

    // header组件高度与占位高度保持一致
    const resizeObserver = new ResizeObserver(entries => {
      if (divRef.current) {
        const headerHeight = entries[0].target.getBoundingClientRect().height;
        divRef.current.style.height = `${headerHeight}px`;
      }
    });
    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }

    //卸载函数
    return () => {
      window.removeEventListener('scroll', onScroll);
      resizeObserver.disconnect();
    }
  }, [defaultAvatar]);



  return (
    <>
      <header ref={headerRef}
        className={`sticky top-0 w-full text-gray-700 py-3 px-6 flex justify-between items-center z-50 transition-all duration-300 ${scrollPosition <= 64 ? defaultBgClassName : scrollBgClassName}`}>
        <button 
          className={`text-2xl font-bold text-black cursor-pointer ${logoColorStyle}`}
          onClick={handleLogoClick}
          aria-label="Go to homepage" // 添加可访问性标签
        >
          fileinbox.online
        </button>

        <nav className="flex gap-6 items-center">
          {loginFlag ? (
            <Link className="text-lg hover:text-blue-500" to="/inboxes">
              My Inboxes
            </Link>) : null}
          <Link className="text-lg hover:text-blue-500" to="/#faqs">
            FAQs
          </Link>
          {/* target="_blank" */}
          <Link className="text-lg hover:text-blue-500" to="/#pricing">
            Pricing
          </Link>
          <Link className="text-lg hover:text-blue-500" to="/#contact-us">
            Contact Us
          </Link>
          {!loginFlag && (
            <Link className="text-lg hover:text-blue-500" to="/signin">
              Sign In
            </Link>
          )}
          {loginFlag && (
            <div className="cursor-pointer">
              <AvatarDropDown>
                <Avatar>
                  <AvatarImage src={avartar} />
                  <AvatarFallback>CN</AvatarFallback>
                </Avatar>
              </AvatarDropDown>
            </div>
          )}
        </nav>
      </header>
      {/* 移除占位 div */}
    </>
  )
}

const BoxPageFooter = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const handleLogoClick = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/');
    }
  }

  return (
    <footer className="w-full  bg-blue-50 text-gray-700 py-8 px-6">
      <div className="container mx-auto flex justify-between items-center">

        <div className={`text-2xl font-bold cursor-pointer ${logoColorStyle}`}
          onClick={handleLogoClick}>
          fileinbox.online</div>
        <nav className="flex gap-6">
          <Link className="text-lg hover:text-blue-500" to="/inboxes">
            Inboxes
          </Link>
          <Link className="text-lg hover:text-blue-500" to="/#faqs">
            FAQs
          </Link>
          {/* <Link className="text-lg hover:text-blue-500" to="#">
            Pricing
          </Link> */}
          <Link className="text-lg hover:text-blue-500" to="/#contact-us">
            Contact Us
          </Link>
        </nav>
      </div>
    </footer>
  )
}

export { BoxPageHeader, BoxPageFooter }