import { Link } from "react-router-dom";
import { BoxPageHeader,BoxPageFooter } from "@/components/BoxHeaderFooter";

export default function Component() {
  return (
    <div className="flex flex-col min-h-screen justify-between">
      <BoxPageHeader />
      <div className="bg-gray-50 min-h-screen flex items-center justify-center">
        <div className="text-center">
          <img src='/logo.png' alt="Logo" className="mx-auto max-h-[256px]" />
          <div className="mt-4">
            <p className="text-4xl font-bold">Page not found</p>
            <p className="text-xl mt-2">
              Please check the URL or return to {' '}
              <Link to="/" className="text-blue-500 underline">
                fileinbox.online
              </Link>
            </p>
          </div>
        </div>
      </div>
      <BoxPageFooter />
    </div>

  );
}