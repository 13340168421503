'use client'

import { useEffect } from 'react'
import { motion } from 'framer-motion'
import { CheckCircle2, ChevronLeft, Inbox as InboxIcon } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { useNavigate } from 'react-router-dom'

export default function InboxFileUploaded() {
  const navigate = useNavigate()

  // 页面加载时触发彩带效果
  useEffect(() => {
    const confetti = {
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    }
    
    import('canvas-confetti').then(canvas => {
      canvas.default(confetti)
    })
  }, [])

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white dark:from-green-950/30 dark:to-background flex items-center justify-center p-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <motion.div 
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ 
              type: "spring",
              stiffness: 260,
              damping: 20 
            }}
            className="w-16 h-16 rounded-full bg-green-100 dark:bg-green-900/30 flex items-center justify-center mx-auto mb-4"
          >
            <CheckCircle2 className="w-8 h-8 text-green-600 dark:text-green-500" />
          </motion.div>
          <CardTitle className="text-center text-2xl font-bold">Upload Complete!</CardTitle>
        </CardHeader>
        <CardContent className="text-center">
          <p className="text-muted-foreground">
            Your file has been uploaded successfully. You can now go to homepage or upload more files.
          </p>
        </CardContent>
        <CardFooter className="flex flex-col gap-2">
          <Button 
            className="w-full" 
            size="lg"
            onClick={() => navigate('/')}
          >
            <InboxIcon className="mr-2 h-4 w-4" />
            Go to Home
          </Button>
          <Button 
            variant="outline" 
            className="w-full" 
            size="lg"
            onClick={() => navigate(-1)}
          >
            <ChevronLeft className="mr-2 h-4 w-4" />
            Upload Another File
          </Button>
        </CardFooter>
      </Card>
    </div>
  )
}
