import { Table, TableHeader, TableBody, TableRow, TableCell, TableHead } from "@/components/ui/table"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Eye, Download, Trash } from "lucide-react"
import { InboxFileModel } from "@/common/model/response/InboxRespModel"
import CustomPagination from '@/components/CustomPagination'
import { formatFileSize } from "@/common/util/FileUtil"
import { formatDateTime } from "@/common/util/DateUtil"
import { Loader2 } from "lucide-react"
import { Checkbox } from "@/components/ui/checkbox"
import { cn } from "@/components/lib/utils"
import { CheckIcon, MinusIcon } from "lucide-react"

interface FileTableProps {
  files: InboxFileModel[]
  currentPage: number
  totalPages: number
  totalCount: number 
  pageSize: number
  isLoading?: boolean
  isSearching?: boolean
  lastSearchTerm?: string
  selectedFiles: string[]
  onPageChange: (page: number, pageSize: number) => void
  onView: (fileId: string) => void
  onDownload: (fileId: string) => void
  onDelete: (fileId: string) => void
  onSelectFile: (fileId: string, isSelected: boolean) => void
  onSelectAll: (isSelected: boolean) => void
}

export function FileTable({ 
  files, 
  currentPage, 
  totalPages, 
  totalCount, 
  pageSize,
  isLoading = false,
  isSearching = false,
  lastSearchTerm = '',
  selectedFiles,
  onPageChange,
  onView,
  onDownload,
  onDelete,
  onSelectFile,
  onSelectAll 
}: FileTableProps) {
  if (isLoading || isSearching) {
    return (
      <div className="flex-grow flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    )
  }

  if (files.length === 0) {
    if (lastSearchTerm) {
      return (
        <div className="text-center py-12 flex-grow flex flex-col justify-center items-center">
          <h3 className="text-2xl font-semibold mb-4">No matching files found</h3>
          <p className="text-gray-500 mb-6">
            We couldn't find any files matching your search "{lastSearchTerm}".
            Try adjusting your search terms or clear the search to see all files.
          </p>
        </div>
      )
    }

    return (
      <div className="text-center py-12 flex-grow flex flex-col justify-center items-center">
        <h3 className="text-2xl font-semibold mb-4">No files yet</h3>
        <p className="text-gray-500 mb-6">
          This inbox is empty. Invite others to submit files and start collaborating!
        </p>
      </div>
    )
  }

  const isAllSelected = files.length > 0 && selectedFiles.length === files.length
  const isIndeterminate = selectedFiles.length > 0 && selectedFiles.length < files.length

  return (
    <div className="py-4 flex-grow flex flex-col">
      <div className="flex-grow">
        <Table>
          <TableHeader>
            <TableRow className="select-none">
              <TableHead className="w-[50px]">
                <Checkbox 
                  checked={isAllSelected}
                  data-state={isIndeterminate ? "indeterminate" : isAllSelected ? "checked" : "unchecked"}
                  onCheckedChange={(checked) => {
                    onSelectAll(checked as boolean)
                  }}
                  className={cn(
                    "data-[state=indeterminate]:bg-primary",
                    "data-[state=indeterminate]:text-primary-foreground"
                  )}
                >
                  <div className="flex items-center justify-center">
                    {isIndeterminate ? (
                      <MinusIcon className="h-3 w-3" />
                    ) : (
                      <CheckIcon className="h-3 w-3" />
                    )}
                  </div>
                </Checkbox>
              </TableHead>
              <TableHead className="w-[40%]">File name</TableHead>
              <TableHead className="w-[20%]">Submit time</TableHead>
              <TableHead className="w-[10%]">Type</TableHead>
              <TableHead className="w-[10%]">File size</TableHead>
              <TableHead className="w-[20%] text-right">Operate</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {files.map((file) => (
              <TableRow key={file.fileId}>
                <TableCell>
                  <Checkbox
                    checked={selectedFiles.includes(file.fileId)}
                    onCheckedChange={(checked) => {
                      onSelectFile(file.fileId, checked as boolean)
                    }}
                  />
                </TableCell>
                <TableCell className="truncate max-w-xs">{file.fileName}</TableCell>
                <TableCell>{formatDateTime(new Date(file.ctime))}</TableCell>
                <TableCell>
                  <Badge variant="secondary">{file.fileType}</Badge>
                </TableCell>
                <TableCell>{formatFileSize(file.fileBytesSize)}</TableCell>
                <TableCell className="flex justify-end space-x-2">
                  <Button variant="ghost" size="sm" onClick={() => onView(file.fileId)}>
                    <Eye className="w-4 h-4" />
                  </Button>
                  <Button variant="ghost" size="sm" onClick={() => onDownload(file.fileId)}>
                    <Download className="w-4 h-4" />
                  </Button>
                  <Button variant="ghost" size="sm" onClick={() => onDelete(file.fileId)}>
                    <Trash className="w-4 h-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="mt-auto py-4">
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          totalCount={totalCount}
          initPageSize={pageSize}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  )
} 