import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Search, X, Download, RefreshCcw, Trash } from "lucide-react"

interface FileSearchBarProps {
  searchTerm: string
  onSearch: (value: string) => void
  onClear: () => void
  onRefresh: () => void
  onDownloadAll: () => void
  selectedCount: number
  onBatchDelete: () => void
  onBatchDownload: () => void
}

export function FileSearchBar({ searchTerm, onSearch, onClear, onRefresh, onDownloadAll, selectedCount, onBatchDelete, onBatchDownload }: FileSearchBarProps) {
  return (
    <div className="py-4 flex justify-between items-center">
      <div className="relative flex-grow max-w-md">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <Input
          type="text"
          placeholder="Search files..."
          value={searchTerm}
          onChange={(e) => onSearch(e.target.value)}
          className="pl-10 pr-10"
        />
        {searchTerm && (
          <button
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
            onClick={onClear}
          >
            <X className="h-4 w-4" />
          </button>
        )}
      </div>
      <div className="flex space-x-2">
        {selectedCount > 0 && (
          <>
            <Button 
              variant="outline" 
              onClick={onBatchDownload}
              className="text-blue-600"
            >
              <Download className="w-4 h-4 mr-2" />
              Download ({selectedCount})
            </Button>
            <Button 
              variant="outline" 
              onClick={onBatchDelete}
              className="text-red-600"
            >
              <Trash className="w-4 h-4 mr-2" />
              Delete ({selectedCount})
            </Button>
          </>
        )}
        {selectedCount === 0 && (
          <Button variant="outline" onClick={onDownloadAll}>
            <Download className="w-4 h-4 mr-2" />
            Download all
          </Button>
        )}
        <Button variant="outline" onClick={onRefresh}>
          <RefreshCcw className="w-4 h-4 mr-2" />
          Refresh
        </Button>
      </div>
    </div>
  )
} 