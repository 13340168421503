
/**
 * 存储空间统计
 */
class DashBoardStatisticsStorageModel {
    // 当前计划限制使用的存储空间
    limitStorageBytes: number = 0;
    // 当前已使用存储空间
    currentStorageBytes: number = 0;
}

/**
 * 收件夹统计
 */
class DashBoardStatisticsInboxModel {
    // 收件夹总数
    totalSize: number = 0;
    // 状态正常的收件夹数量
    activeSize: number = 0;
    // 已暂停收件夹数量
    pausedSize: number = 0;
    // 将过期的收件夹数量
    closingSoonSize: number = 0;
    // 已过期的收件夹数量
    expiredSize: number = 0;
}

/**
 * 最近活动
 */
class DashBoardRecentActivityModel {
    // 类型
    activityType: string = '';
    // 时间
    timestamp: string = '';
    // 活动描述
    description: string = '';
    // 操作人
    operateUserId: string = '';
}


export { DashBoardStatisticsStorageModel, DashBoardStatisticsInboxModel, DashBoardRecentActivityModel };
